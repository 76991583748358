import React from "react"
import styled from "styled-components"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import ProImg from "../../assets/images/profissional-saude5.svg"
import Button from "../../components/profissionaisSaude/Inputs/Button"



const Content = styled.section`
  width: 100%;
  max-width: 68.75rem;
  min-height: calc(100vh - 13.25rem);
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 3rem 0 5.956rem;
  gap: 1.375rem;

  @media screen and (max-width: 1020px) {
    padding: 1rem 2.5rem;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`

const ContentInfos = styled.div`
  max-width: 445px;
`

const ImageWrapper = styled.div`
  position: relative;
  max-width: 475px;
  max-height: 392px;
`

const Image = styled(ProImg)`
  max-width: 475px;
  max-height: 392px;
  position: relative;
`

const ImageCredit = styled.span`
  color: #515151;
  font-size: 0.75rem;
  position: absolute;
  bottom: 0;
  right: 2rem;
`

const ImageCreditLink = styled.a`
  text-decoration: underline;
`

const Heading = styled.div`
  width: 100%;
  color: #1f1f1f;
  font-family: "Nunito";
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
`

const Text = styled.p`
  font-family: "Nunito";
  font-size: 16px;
  color: #1f1f1f;
  
`
const BoxText = styled.div`
  display: flex ;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
`
export default function FinalizarCadastroLocalidadeIndisponivel() {
  return (
    <>
      <Header />
      <Content>
        <ContentInfos>
          <Heading>Logo traremos novidades!</Heading>
          <BoxText>
            <Text>
              Estamos expandindo (ou aumentando?) a região de atuação da Lacrei Saúde a cada dia. 
            </Text>
            <Text>Vamos te avisar por e-mail assim que chegarmos no seu Estado!</Text>
          </BoxText>
          <Button  link="/saude/painel-inicial" title="Voltar para o painel" />
        </ContentInfos>
        <ImageWrapper>
          <Image />
          <ImageCredit>
            Ilustrado por{" "}
            <ImageCreditLink target={"_blank"} href="https://br.freepik.com/">
              Freepik
            </ImageCreditLink>
          </ImageCredit>
        </ImageWrapper>
      </Content>
      <Footer  hasButtonUp/>
    </>
  )
}

